import * as React from 'react';
import { graphql } from 'gatsby';
import { useState } from 'react';
import Layout from '../../components/layout';
import { Header } from '../../components/atoms';
import Feature from '../../components/feature';
import { StaticImage } from 'gatsby-plugin-image';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    company: '',
    email: '',
  });

  function handleChange(event, field) {
    setForm({ ...form, [field]: event.target.value });
  }

  return (
    <Layout
      title="デモのお申し込み"
      description="AI特許調査プラットフォームAmplifiedに「シェア」「アノテーション」「チャット」の3つの新機能を紹介します。デモでは、全体像と個別機能をご説明しながら、ご質問にもお答えします。ご興味あれば、トライアルや導入までの流れについてご相談も可能です。"
    >
      <Feature
        side="right"
        header="Amplifiedの新機能"
        subheader="デモお申込み"
        body="AI特許調査プラットフォームAmplifiedに「シェア」「アノテーション」「チャット」の3つの新機能を紹介します。デモでは、全体像と個別機能をご説明しながら、ご質問にもお答えします。ご興味あれば、トライアルや導入までの流れについてご相談も可能です。"
        graphic={
          <StaticImage
            src="../../images/cycle.png"
            placeholder="tracedSVG"
            alt="How it works"
          />
        }
      />
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="text-gray_2 absolute left-full transform translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <Header>デモのお申し込み</Header>
          </div>
          <div className="mt-12">
            <form
              data-netlify="true"
              netlify-honeypot="bot-field"
              name="knowledge-automation-learn-more-ja"
              action="/success"
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <input
                type="hidden"
                name="form-name"
                value="knowledge-automation-learn-more-ja"
              />
              <input type="hidden" name="bot-field" />
              <div className="sm:col-span-2">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray_4"
                >
                  お名前（姓）
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    autoComplete="family-name"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'lastname')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray_4"
                >
                  （名）
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    autoComplete="given-name"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'firstname')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray_4"
                >
                  メールアドレス
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'email')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray_4"
                >
                  企業名
                </label>
                <div className="mt-1">
                  <input
                    id="company"
                    name="company"
                    type="text"
                    autoComplete="organization"
                    className="block w-full py-3 text-base text-chocolate hover:bg-cake_yellow rounded-md placeholder-gray_3 shadow-sm focus:ring-bright_gold focus:border-bright_gold sm:flex-1 border-gray_3"
                    onChange={(event) => handleChange(event, 'company')}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className={classNames(
                    Object.values(form).every((val) => !!val)
                      ? 'bg-pastel_red hover:bg-pastel_red_120'
                      : 'bg-gray_3',
                    'rounded-md w-full px-8 py-4 flex items-center justify-center text-lg leading-6 font-medium text-white md:px-10 w-full'
                  )}
                  disabled={Object.values(form).every((val) => !val)}
                >
                  デモを申し込む
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
